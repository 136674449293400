<template>
  <div class="mly-loading-wrap" v-show="visible">
    <div class="mly-loading" :style="{top:`${top}rem`}"></div>
  </div>
</template>

<script>
export default {
  name: "mlyLoading",
  data() {
    return {
      visible: false,
      top: 6 //绝对定位距离顶部 x像素
    };
  },
  watch: {
    visible(newVal) {
      document.body.style.overflow = newVal ? "hidden" : "auto";
    }
  },
  methods: {
    show(top) {
      if (top) {
        this.top = top;
      }
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.top = 6;
    }
  }
};
</script>

<style>
@keyframes loading {
  to {
    background-position: -800px 0;
  }
}

.mly-loading-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mly-loading {
  width: 100px;
  height: 100px;
  background: url(~@/assets/icon_loading.png) 0 0;
  animation: loading 1s infinite steps(8);
  position: absolute;
  left: 50%;
  margin-left: -50px;
}
</style>
