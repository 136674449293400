// 该文件主要是做一些配置、初始化和组件的导入、注册等工作
import Vue from 'vue';
import axios from 'axios';
import vueEasyToast from 'vue-easy-toast';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueMugenScroll from 'vue-mugen-scroll'

import MLY from '@/components/index';    //导入自定义组件
import '@/commons/device.js'; //device.js 用于获取设备信息
import './rem-v2';  //导入 rem 相关的js; 进行计算时，基准字体大小为100

import util from './util'

Vue.use(MLY);
Vue.use(vueEasyToast);
Vue.use(VueAwesomeSwiper);
Vue.component('mugen-scroll',VueMugenScroll)  //注册无限滚动组件

//全局配置 axios
axios.defaults.baseURL = "https://m.254game.com/";
// axios.defaults.baseURL = "https://www.easy-mock.com/mock/5b487e65c267df600bf70acb/mly_manage";
// axios.defaults.baseURL = "http://10.0.0.73";
axios.defaults.withCredentials = true;

axios.defaults.transformRequest = [function (data) {
  let ret = '';
  for (let it in data) {
    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
  }
  return ret;
}];

// 剔除响应对象的包装，请求的回调函数直接返回响应数据
axios.interceptors.response.use(response => {
  let ret = response.data;
  return ret;
});

// 修复在chrome响应式工具中打开，从移动端切换到pc端的时候，元素内容会增大的问题
window.onresize = function () {
  flex(false, 100, 1);
}

// 自定义的全局对象
window.GlobalObject = {
  UserInit: function () {
    const mlyol_userinfo = util.getCookieValue('mlyol_userinfo');
    if (!mlyol_userinfo) {
      return undefined;
    }
    mlyol_userinfo.userId = mlyol_userinfo.ACCOUNT_ID;
    mlyol_userinfo.username=mlyol_userinfo.LOGIN_ACCOUNT;
    return mlyol_userinfo;
  },
};

let InvoiceUser=sessionStorage.getItem("InvoiceUser");
if(InvoiceUser){
  /**
   * 开发票登录的用户信息
   */
  GlobalObject.InvoiceUser=JSON.parse(InvoiceUser);
}


//把初始化的用户信息定义在全局对象上,如果没有登录就是 undefined
GlobalObject.User = GlobalObject.UserInit();
