/**
 * 定义了常用的工具函数
 */

import Vue from 'vue';
import vueEasyToast from 'vue-easy-toast';
Vue.use(vueEasyToast);

import wxtip from '../components/mly-wxtip'
import {
  showGiftModal,
  showPromptModal
} from '../components/mly-modal'

import {
  showLoading,
  hideLoading
} from '../components/mly-loading'

export default {
  //返回一个不带有双向绑定的对象
  copy(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  //判断值是否为空
  isEmpty(value) {
    if (value === null || value === '' || value === 'null' || value === undefined) {
      return true;
    }
    return false;
  },
  //获取url中的参数
  getUrlParam(paramName) {
    let reg = new RegExp("(^|&)" + paramName + "=([^&]*)(&|$)");
    let r = location.search.substr(location.search.indexOf("?") + 1).match(reg);
    if (r != null) {
      return decodeURI(r[2]);
    }
    return undefined;
  },
  //获取cookie值
  getCookieValue(name) {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) {
      return JSON.parse(arr[2]);
    } else {
      return undefined;
    }
  },
  //设置cookie
  setCookie(name, value, day = 30) { //默认保存30天
    let expires = '';
    if (day != null) {
      const exp = new Date();
      exp.setTime(exp.getTime() + day * 24 * 60 * 60 * 1000);
      expires = ";expires=" + exp.toGMTString();
    }
    document.cookie=`${name}=${JSON.stringify(value)}${expires};path=/`;
  },
  //删除cookie
  delCookie(name) {
    const exp = new Date();
    exp.setTime(exp.getTime() - 1);
    const cval = this.getCookieValue(name);
    if (cval != null)
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  },
  //弹出吐司消息
  toast(text, duration = 3000) {
    Vue.toast(text, {
      duration: duration,
      mode: 'override',
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      className: ['totast', 'totast-text']
    });
  },
  wxtip,
  showGiftModal,
  showPromptModal,
  showLoading,
  hideLoading
}
