<template>
  <mly-modal v-show="visible">
    <div class="modal-gifts">
      <p>{{msg}}</p>
      <img src="@/assets/modal_affirm.png" alt class="img-responsive" @click="close">
    </div>
  </mly-modal>
</template>

<script>
import mlyModal from "./mly-modal";

export default {
  components: {
    mlyModal
  },
  data() {
    return {
      visible: false,
      msg: ""
    };
  },
  methods: {
    close() {
      this.visible = false;
    }
  }
};
</script>

<style>
.modal-gifts {
  width: 7.2rem;
  height: 5.66rem;
  margin: 2.56rem auto;
  background-image: url(~@/assets/modal_gifts.png);
  background-position: center center;
  background-size: cover;
  position: relative;
}

.modal-gifts p {
  color: white;
  padding-top: 3.62rem;
  text-align: center;
}
.modal-gifts img {
  width: 2.23rem;
  height: 0.82rem;
  position: absolute;
  left: 50%;
  bottom: 0.56rem;
  margin-left: -1.115rem;
}
</style>
