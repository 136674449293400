import { render, staticRenderFns } from "./mly-placeholder.vue?vue&type=template&id=1fe6bdf4&"
import script from "./mly-placeholder.vue?vue&type=script&lang=js&"
export * from "./mly-placeholder.vue?vue&type=script&lang=js&"
import style0 from "./mly-placeholder.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "mly-placeholder.vue"
export default component.exports