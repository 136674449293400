import Vue from 'vue'
import mlyLoading from './mly-loading.vue'

const instance = new(Vue.extend(mlyLoading))();
if (!instance.$el) {
  let vm = instance.$mount();
  document.body.appendChild(vm.$el);
}

const showLoading = instance.show;
const hideLoading = instance.hide;

export {
  /**
   * @type { function():void }
   */
  showLoading,
  hideLoading
}
