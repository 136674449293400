<template>
  <keep-alive :include="['index','game']">
    <router-view class="content-wrap"/>
  </keep-alive>
</template>
<script>
export default {
  name: "App"
};
</script>
<style src="swiper/dist/css/swiper.css"></style>
<!--
整个页面的自定义公共样式，后期要进行重构，拆分到组件中去
-->
<style>
html,
body {
  width: 100%;
  height: 100%;
}

.content-wrap {
  max-width: 10rem;
  margin: 0 auto;
}

/*a标签样式重置 begin*/
.a-label-reset:hover,
.a-label-reset:visited,
.a-label-reset:link,
.a-label-reset:active {
  font-family: "微软雅黑";
  text-decoration: none;
  color: #333;
}
/*a标签样式重置 end*/

.flex {
  display: flex;
}

p {
  margin: 0;
}

div,
span,
p,
h1,
button {
  font-family: "微软雅黑";
}

/* 清除IE的文本框和密码框默认样式 */
::-ms-clear,
::-ms-reveal {
  display: none;
}

/* 滚动条样式 */

::-webkit-scrollbar {
  width: 0;
  height: 16px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar:horizontal {
  width: 5px;
  height: 6px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c9c9c9;
}

.description {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.7rem;
}

.description p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.21rem;
}

.description p:nth-child(1) {
  font-size: 0.29rem;
  color: black;
}

.description p:nth-child(2),
.description p:nth-child(3) {
  color: #868686;
}

.list-item {
  padding: 0.3rem 0.25rem;
  border-bottom: 0.02rem solid rgb(231, 231, 231);
  display: flex;
  align-items: center;
}

.list-item:hover,
.list-item:visited,
.list-item:link,
.list-item:active {
  text-decoration: none;
}

.list-item img {
  width: 1.14rem;
  height: 1.14rem;
  margin-right: 0.2rem;
}

.list-item button {
  color: white;
  border-radius: 0.1rem;
  background-color: #20a2dc;
  font-size: 0.25rem;
  width: 1.2rem;
  padding: 0.12rem 0;
  border: none;
  align-self: flex-end;
}

.foot {
  text-align: center;
  font-size: 0.23rem;
  border-bottom: 0.2rem #f4f4f4 solid;
  padding: 0.32rem;
}

.foot a {
  text-decoration: none;
  color: #868686;
}

/* 吐司样式 */

.totast {
  position: fixed;
  bottom: 2.5rem !important;
  left: 50%;
  z-index: 1000;
  padding: 0.1rem;
  max-width: 80% !important;
  border-radius: 0.05rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.totast-text span {
  font-size: 0.24rem;
}
</style>
