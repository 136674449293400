<template>
  <mly-modal v-show="visible">
    <div :class="$style.wrap">
      <div :class="$style.title">
        <p>提示</p>
        <img src="@/assets/modal_close.png" alt :class="$style.close" @click="close">
      </div>
      <div :class="$style.box">
        <div :class="$style.content" v-html="msg"></div>
        <div class="btn-group" v-if="withButton">
          <button type="button" class="btn" @click="handleConfirmClick">确定</button>
          <button type="button" class="btn pull-right" @click="close">取消</button>
        </div>
      </div>
    </div>
  </mly-modal>
</template>

<script>
import mlyModal from "./mly-modal";

export default {
  components: {
    mlyModal
  },
  data() {
    return {
      visible: false,
      msg: "",
      withButton: false,
      confirmCallback: function() {}
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    handleConfirmClick() {
      this.confirmCallback();
      this.visible = false;
    }
  }
};
</script>

<style module>
.wrap {
  width: 5.12rem;
  margin: 2.56rem auto;
}
.title {
  width: 5.12rem;
  height: 0.77rem;
  background-image: url(@/assets/modal_title.png);
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 140;
}
.title p {
  color: white;
  font-size: 0.3rem;
  padding-top: 0.1rem;
}

.close {
  width: 0.45rem;
  height: 0.45rem;
  position: absolute;
  right: 0;
  top: -0.6rem;
}

.box {
  width: 4.32rem;
  margin: 0 auto;
  padding: 0.4rem;
  background-color: #fff;
  position: relative;
  top: -0.15rem;
  z-index: 121;
}
.content {
  font-size: 0.25rem;
  text-align: center;
}

.box :global(.btn-group) {
  margin: 0.4rem 0 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.box button {
  width: 1.52rem;
  height: 0.56rem;
  margin: 0;
  font-size: 0.25rem;
  color: white;
  background-color: #ff5e5e;
  border-radius: 0.04rem;
}
.box button:hover,
.box button:active,
.box button:visited,
.box button:focus,
.box button:focus-within {
  color: white;
}
.box button:last-child {
  background-color: #a3a3a3;
}
</style>
