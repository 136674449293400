<template>
  <div :class="$style.wrap">
    <router-link
      class="list-item"
      v-for="item in gameList"
      :key="item.id"
      :to="'/mobile-games/detail/'+item.id"
    >
      <img :src="item.icon" class="img-responsive" alt="">
      <div class="description">
        <p>{{item.name}}</p>
        <p>{{item.gameTypeName}} | {{item.gameSize}}M</p>
        <p>{{item.content}}</p>
      </div>
      <button type="button" @click.prevent="downloadGame(item)">下载</button>
    </router-link>
    <slot name="append"></slot>
  </div>
</template>

<script>
import Const from '../commons/const.js'
import util from '../commons/util.js'

export default {
  name: "mlyGameList",
  props: {
    gameList: {
      type: Array,
      required: true
    }
  },
  methods: {
    downloadGame(game) {
      if (device.ios()) {
        if (util.isEmpty(game.iosDownUrl)) {
          util.toast("上线在即，敬请期待^^");
        } else {
          window.open(game.iosDownUrl, "_blank");
        }
      } else {
        if (Const.weixin) {
          util.wxtip();
        } else {
          window.open(game.downUrl, "_blank");
        }
      }
    }
  }
};
</script>

<style module>
.wrap .foot {
  border: none;
  margin-bottom: 1.34rem;
}
</style>
