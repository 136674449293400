<template>
  <section class="mly-modal">
    <slot></slot>
  </section>
</template>

<script>
export default {};
</script>

<style>
.mly-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 120;
  width: 10rem;
  margin:0 auto;
  background-color: rgba(25, 25, 25, 0.9);
}
.mly-modal p {
  font-size: 0.25rem;
  text-align: center;
}
</style>
