<template>
  <div class="wxtip" v-show="visible" @click="visible=false">
    <img src="@/assets/wxtip.png" alt>
  </div>
</template>
<script>
export default {
  name: "mlyWxtip",
  data() {
    return {
      visible: false
    };
  },
  methods: {
    show() {
      this.visible = true;
    }
  }
};
</script>
<style>
.wxtip {
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 1000;
  opacity: 0.95;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.wxtip img {
  width: 2.51rem;
  height: 2.53rem;
  position: absolute;
  right: 0;
}
</style>
