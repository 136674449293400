// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router/index'

import './commons/index';

Vue.config.productionTip = false

// 在开发模式下，启动mock服务
if (process.env.NODE_ENV !== 'production') {
  require('../mock/index')
  console.log('Mock服务启动')
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
