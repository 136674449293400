<template>
  <header :class="$style.wrap">
    <!-- left begin -->
    <a href="javascript:history.go(-1)" v-if="style2" :class="$style.goBack">
      <img src="@/assets/icon_left_arrow.png" alt>
    </a>
    <img src="@/assets/mly_logo.png" alt :class="$style.logo" v-else>
    <!-- left end -->
    <div :class="$style.menu">
      <router-link to="/user">
        <img src="@/assets/logo_me.png" alt>
      </router-link>
      <img src="@/assets/logo_list.png" alt>
    </div>
  </header>
</template>
<script>
export default {
  name: "mlyHeader",
  props: {
    /*
     *表示是否使用第二个样式，即默认左上角是logo（即第一个样式），
     *如果设置使用第二个样式，logo会替换为左箭头，并添加点击返回
     */
    style2: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
<style module>
.wrap {
  background-color: #20a2dc;
  padding: 0.1rem 0.25rem;
  display: flex;
  justify-content: space-between;
}

.wrap img {
  max-width: 100%;
}

.logo {
  width: 0.81rem;
  height: 0.5rem;
}

.go-back {
  font-size: 0.3rem;
  width: 0.6rem;
  height: 0.5rem;
}

.go-back img {
  width: 0.17rem;
  height: 0.3rem;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu img {
  width: 0.35rem;
  height: 0.35rem;
  display: block;
}

.menu > img:nth-child(2) {
  margin-left: 0.15rem;
}
</style>
