// 自动注册当前目录下的所有组件
export default {
  install(Vue) {
    let contexts = require.context('.', false, /\.vue$/)
    contexts.keys().forEach(component => {
      let componentEntity = contexts(component).default
      if (!componentEntity.name) {
        console.error(`组件${componentEntity.__file}需要指定name属性`);
      }
      Vue.component(componentEntity.name, componentEntity)
    })
  }
}
