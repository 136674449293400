<template>
  <div class="wrap" :class="fullScreen?'full_screen':''">
    <div class="top flex">
      <img src="@/assets/logo_header.png" alt="" @click="handleLogoClick">
      <a href="javascript:void(0)" class="a-label-reset" @click="lianxikefu">联系客服</a>
    </div>

    <div class="center">

      <section id="mobile-box">
        <iframe :src="path" frameborder="0"></iframe>
      </section>
      <div id="full-screen-btn" @click="fullScreen=!fullScreen">

      </div>
    </div>

  </div>
</template>
<script>
window.onresize = null; //移除掉窗口尺寸变化事件
import util from '../commons/util.js'
import Const from '../commons/const.js'

export default {
    name:'mlyPcWrap',
    props:{
      path:{
        type:String,
        required:true
      }
    },
    data() {
        return {
            fullScreen: false,
        };
    },
    methods: {
        handleLogoClick() {
            if (Const.isDev) {
                location.href = "http://127.0.0.1:8000/";
            } else {
                location.href = `https://www.${this.getDomain()}.com/`;
            }
        },
        getDomain() {
            var __domain = document.domain;
            var __domain_array = __domain.split(".");
            if (__domain_array.length == 3) {
                __domain = __domain_array[1];
            } else if (__domain_array.length == 2) {
                __domain = __domain_array[0];
            }
            return __domain;
        },
        lianxikefu(){
          util.showPromptModal('QQ：3007385675<br/>受理时间：周一至周五<br/>9:30-20:00');
        }
    }
};
</script>
<style scoped>
.content-wrap {
    max-width: 1200px;
    padding-bottom: 0;
}
</style>

<style >
.mly-modal{
  width:100%;
}

.wrap {
    width: 100%;
    height: 100%;
}
/* 全屏之后覆盖的部分样式 begin */

.full_screen {
    background-color: #000;
}

.full_screen .top {
    display: none;
}

.full_screen .center {
    margin-top: 0;
    background-image: none;
    min-height: 100%;
    background-color: black;
}

.full_screen #mobile-box {
    width: 500px;
    height: 100%;
    margin-left: -250px;
    top: 0;
    bottom: 0;
    padding: 0;
    background-image: none;
}

.full_screen #full-screen-btn {
    margin-left: 250px;
    top: 4%;
}
/* 全屏之后覆盖的部分样式 end */

.top {
    height: 70px;
    display: flex;
    /* justify-content: space-around; */
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.top img {
    /* width: 100px;
    height: 50px; */
    max-width: 100%;
    height: auto;
}

.center {
    background-image: url("~@/assets/pc_index_bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    width: 100%;
    min-height: 834px;
    margin-top: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#mobile-box {
    width: 454px;
    height: 834px;
    background-image: url("~@/assets/pic_phone.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    position: absolute;
    top: -4%;
    left: 50%;
    margin-left: -227px;
    padding: 51px 11px 64px 11px;
    overflow-y: hidden;
}

#mobile-box iframe {
    width: 100%;
    height: 100%;
    padding: 0;
}

#full-screen-btn {
    width: 30px;
    height: 93px;
    background-image: url("~@/assets/btn_fullscreen.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    position: absolute;
    left: 50%;
    margin-left: 227px;
    cursor: pointer;
}

::selection {
    background-color: rgba(0, 0, 0, 0);
}
</style>
