/**
 * 定义了经常用到的常量值
 */

//一般是http get请求成功后台返回的状态码，对应后台属性`returnCode`
const RequestSuccess = '100';

//判断是否运行在微信中
const ua = navigator.userAgent;
const weixin = !!/MicroMessenger/i.test(ua);

//获取用户信息的 cookie的key值
const UserCookieKey = 'mlyol_userinfo';

//获取对应操作系统的编码, 1为pc、2为ios、3为android
let code = 1;
try {
  if (device.ios()) {
    code = 2;
  } else if (device.android()) {
    code = 3;
  }
} catch (error) {
  console.warn('please import device.js');
}

const OSCode = code;

//判断是否为开发环境
const isDev = process.env.NODE_ENV !== "production";

export default {
  RequestSuccess,
  weixin,
  UserCookieKey,
  OSCode,
  isDev
}
