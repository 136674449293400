import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

// 每个页面的标题一样
const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'index',
    component: () => import( /* webpackChunkName: "indexView" */ '@/views/index/main.vue'),
  }, {
    path: '/login',
    name: 'login',
    component: () => import( /* webpackChunkName: "loginView" */ '@/views/login-register/login.vue'),
  }, {
    path: '/register',
    name: 'register',
    component: () => import( /* webpackChunkName: "registerView" */ '@/views/login-register/register.vue'),
  }, {
    path: '/mobile-games/detail/:game_id',
    name: 'gameDetail',
    component: () => import( /* webpackChunkName: "gameDetailView" */ '@/views/game-detail/main.vue'),
    props: true
  }, {
    path: '/mobile-games',
    name: 'game',
    component: () => import( /* webpackChunkName: "gameView" */ '@/views/game/main.vue'),
  }, {
    path: '/game-search/:keyword',
    name: 'gameSearch',
    component: () => import( /* webpackChunkName: "gameSearchView" */ '@/views/game-search.vue'),
  }, {
    path: '/gift',
    name: 'gifts',
    component: () => import( /* webpackChunkName: "giftsView" */ '@/views/gifts/main.vue'),
  }, {
    path: '/h5-gift',
    name: 'h5gift',
    component: () => import( /* webpackChunkName: "h5giftView" */ '@/views/h5-gift.vue'),
  }, {
    path: '/h5-games',
    name: 'h5game',
    component: () => import( /* webpackChunkName: "h5gameView" */ '@/views/h5game.vue'),
  }, {
    path: '/h5',
    name: 'h5',
    component: () => import( /* webpackChunkName: "h5View" */ '@/views/h5.vue'),
  }, {
    path: '/user',
    name: 'user',
    component: () => import( /* webpackChunkName: "meView" */ '@/views/me/main.vue'),
  }, {
    path: '/demo',
    name: 'demo',
    component: () => import( /* webpackChunkName: "demoView" */ '@/views/demo.vue'),
    meta: {
      title: 'demo'
    }
  },
  // 发票相关页面
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import(/* webpackChunkName: "invoiceView" */'@/views/invoice/main.vue'),
  }, {
    path: '/invoice/login',
    name: 'invoice_login',
    component: () => import('@/views/invoice/login.vue')
  }, {
    path: '/invoice/order',
    name: 'invoice_order',
    component: () => import(/* webpackChunkName: "invoiceOrderView" */'@/views/invoice/order.vue'),
  }, {
    path: '/invoice/apply',
    name: 'invoice_apply',
    component: () => import('@/views/invoice/apply.vue'),
  },{
    path:'/invoice/detail',
    name:'invoice_detail',
    component:()=>import('@/views/invoice/detail.vue'),
  }, {
    path:'/invoice/resend',
    name:'invoice_resend',
    component:()=>import('@/views/invoice/resend.vue'),
  },{
    path:'/invoice/re-apply',
    name:'invoice_re_apply',
    component:()=>import('@/views/invoice/re-apply.vue'),
  },{
    path: '/invoice/order/success',
    name: 'invoice_order_success',
    component: () => import(/* webpackChunkName: "invoiceOrderView" */'@/views/invoice/success.vue'),
  }, {
    path: '/invoice/history',
    name: 'invoice_history',
    component: () => import(/* webpackChunkName: "invoiceHistoryView" */'@/views/invoice/history.vue'),
  }, {
    path: '/invoice/help',
    name: 'invoice_help',
    component: () => import(/* webpackChunkName: "invoiceHelpView" */'@/views/invoice/help.vue'),
  }, {
    path: '/invoice/verify-phone',
    name: 'invoice_verify_phone',
    component: () => import('@/views/invoice/verify-phone.vue'),
  }, {
    path: '*',
    component: () => import( /* webpackChunkName: "pageNotFoundView" */ '@/views/page-not-found.vue'),
    meta: {
      title: 'pageNotFound'
    }
  }]
});

// 进入开发票页面后，如果没有登录则跳转到登录页面
// const arr = ['invoice', 'invoice_order', 'invoice_history', 'invoice_help', 'invoice_verify_phone']

router.beforeEach((to, from, next) => {
  console.log(to.name)
  if (to.name.startsWith('invoice')&&to.name!=='invoice_login') {
    if (!GlobalObject.InvoiceUser) {
      next('/invoice/login')
      return;
    }
  }
  next();
  // 路由切换完成之后，先销毁事件处理总线，再初始化事件处理总线，相当于把上一个路由的事件处理总线中所有的事件监听解绑
  window.EventBus = undefined;
  window.EventBus = new Vue();
});

export default router;
