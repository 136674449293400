import Vue from 'vue'
import mlyWxtip from './mly-wxtip.vue'

const instance = new(Vue.extend(mlyWxtip))();
if (!instance.$el) {
  const vm = instance.$mount();
  document.body.appendChild(vm.$el);
}

export default instance.show
