import Vue from 'vue'

import mlyModalGift from './mly-modal-gift.vue'
import mlyModalPrompt from './mly-modal-prompt.vue'

// 实例化并渲染礼包弹框
let mlyModalGiftInstance = new(Vue.extend(mlyModalGift))();
if (!mlyModalGiftInstance.$el) {
  renderToHtml(mlyModalGiftInstance);
}

function showGiftModal(msg) {
  if (!msg) {
    throw new Error('必须传入参数msg')
  }
  mlyModalGiftInstance.visible = true;
  mlyModalGiftInstance.msg = msg;
}

//实例化并渲染提示信息弹框
let mlyModalPromptInstance = new(Vue.extend(mlyModalPrompt))();
if (!mlyModalPromptInstance.$el) {
  renderToHtml(mlyModalPromptInstance);

}

function showPromptModal(msg,confirmCallback) {
  if (!msg) {
    throw new Error('必须传入参数msg')
  }

  if(confirmCallback){
    mlyModalPromptInstance.confirmCallback = confirmCallback;
    mlyModalPromptInstance.withButton = true;
  }else{
    mlyModalPromptInstance.withButton = false;
  }

  mlyModalPromptInstance.visible = true;
  mlyModalPromptInstance.msg = msg;
}

function renderToHtml(componentInstance) {
  let vm = componentInstance.$mount();
  document.body.appendChild(vm.$el);
}

export {
  showGiftModal,
  showPromptModal
}
