<!--
占位空元素,用于处理被底部菜单栏遮挡的部分，
使用时加在最底部进行占位
-->
<template>
  <div class="mly-placeholder"></div>
</template>

<script>
export default {
  name:'mlyPlaceholder'
};
</script>

<style>
.mly-placeholder {
  display: block;
  width: 100%;
  height: 1.34rem;
}
</style>
