<template>
  <section id="toolbar">
    <router-link to="/" type="button" class="btn btn-default" :class="{active:curr_route_name=='index'}">
      <img src="@/assets/toolbar_home.png" alt="" class="img-responsive">
      <p>首页</p>
    </router-link>
    <router-link to="/mobile-games" type="button" class="btn btn-default" :class="{active:curr_route_name=='game'}">
      <img src="@/assets/toolbar_game.png" alt="" class="img-responsive">
      <p>手游</p>
    </router-link>
    <!-- <router-link to="/h5-games" type="button" class="btn btn-default" :class="{active:curr_route_name=='h5game'}">
      <img src="@/assets/toolbar_game.png" alt="" class="img-responsive">
      <p>h5游戏</p>
    </router-link> -->
    <router-link to="/gift" type="button" class="btn btn-default" :class="{active:curr_route_name=='gifts'}">
      <img src="@/assets/toolbar_gifts.png" alt="" class="img-responsive">
      <p>礼包</p>
    </router-link>
    <router-link to="/user" type="button" class="btn btn-default" :class="{active:curr_route_name=='user'}">
      <img src="@/assets/toolbar_me.png" alt="" class="img-responsive">
      <p>我</p>
    </router-link>
  </section>
</template>
<script>
export default {
    name: "mlyToolbar",
    created(){
      // console.log(this.$route);
    },
    computed:{
      //当前路由名称
      curr_route_name(){
        return this.$route.name;
      }
    },
    data(){
      return {

      };
    }
};
</script>
<style>
#toolbar {
    width: 100%;
    height: 1.34rem;
    border-top: 0.02rem #f4f4f4 solid;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #20a2dc;
    z-index: 100;
    display: flex;
    max-width: 10rem;
    margin: 0 auto;
}

#toolbar a {
    box-shadow: none;
    border-radius: 0;
    font-size: 0.25rem;
    border: none;
    outline: none;
    background-color: #20a2dc;
    color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#toolbar .active {
    background-color: #ff5d5e;
}

#toolbar img {
    width: 0.55rem;
    height: 0.55rem;
}

#toolbar a span {
    color: black;
}
</style>
